// Import global vars
@import "master";
@import "fonts";

:root {
  --color-primary: #faae5d;
  --color-primary-alt: #c17524;
  // --gradient-primary: linear-gradient(90deg, #ffd977 0%, #faae5d 100%);
  --gradient-primary: linear-gradient(90deg, #ffd977 0%, #ff9b30 100%);
  --gradient-primary-alt: linear-gradient(90deg, #faae5d 0%, #ffd977 100%);
  // --gradient-white: linear-gradient(90deg, #fff 0%, #d0d0d0 100%);
  --gradient-white: linear-gradient(90deg, #fff 0%, #bdbdbd 100%);
  --gradient-white-alt: linear-gradient(90deg, #d0d0d0 0%, #fff 100%);
  --color-grey: #f7f7f7;
  // --color-primary-alt: #fcd200;
  // --color-accent: #00aaff;
  // --color-accent-alt: #2590c6;
  // --color-accent-light: #aee3fd;
  // --color-text: #2f3139;
  // --color-text-blue: #230055;

  // --color-background: #fff;
  // --color-background-alt: #f5f7f7;
}

html,
body {
  height: 100%;
  padding: 0;
}
body {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  // font-family: 'Titillium Web', serif;
  font-family: "Roboto", sans-serif;
  color: #000;
}
.main-content {
  flex: 1 0 auto;
}
.main-footer {
  flex-shrink: 0;
}

img {
  max-width: 100%;
}

.ty-button {
  padding: 14px 60px;

  border-radius: 34px;
  background: var(--gradient-primary);
  background-size: 150% 1px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);

  font-family: "Titillium Web";
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #fff;

  border: none;
  display: inline-block;

  transition: 300ms;
  &:hover {
    text-decoration: none;
    color: #fff;
    // background: var(--gradient-primary-alt);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
    background-position: -100px;
  }

  &--white {
    background: var(--gradient-white);
    background-size: 150% 1px;
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary);
      // background: var(--gradient-white-alt);
    }
  }
}

.ty-input-wrap {
  position: relative;
  margin-bottom: 20px;
}
.ty-input {
  border: none;
  border-radius: 20px;
  padding: 20px 20px;
  font-size: 1rem;
  line-height: 140%;
  outline: none !important;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
}
.ty-label {
  font-weight: 300;
  font-size: 20px;
  line-height: 38px;
  color: var(--color-primary-alt);
}

.mw-limited {
  max-width: 1100px;
}

.main-header {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

    padding: 8px 0;
    .main-header__logo {
      width: 50px;
    }
    .navbar-nav .nav-item .nav-link {
      font-size: 14px;
    }
  }

  &__logo {
  }

  .navbar-toggler {
    border: none;
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: 0 30px;

        font-weight: 300;
        font-size: 18px;
        color: var(--color-primary-alt);

        transition: 300ms;
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
  .language-switcher {
    .nav-link {
      i {
        font-size: 25px;
        background: var(--gradient-primary);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:hover {
        i {
          background: var(--gradient-primary-alt);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

.main-footer {
  padding: 10px 0;
  background: var(--color-primary-alt);
  color: #fff;

  .copyright-text {
    font-weight: 300;
    font-size: 15px;
  }

  .socials-menu {
    .nav-link {
      i {
        font-size: 25px;
        color: #fff;
      }
    }
  }
}

.section {
  padding: 80px 0;

  &__title {
    display: inline-block;
    font-family: "Titillium Web";
    font-weight: bold;
    font-size: 40px;
    // text-transform: uppercase;
    color: var(--color-primary);

    margin-bottom: 60px;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 80%;
      height: 1px;
      left: 0;
      bottom: -30px;
      background: #000;
    }

    &--center {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &--black {
      color: #000;
    }
    &--no-underline {
      &::after {
        display: none;
      }
    }
  }
  &__text {
    font-size: 20px;
  }

  &--grey {
    background: var(--color-grey);
  }
  &--gradient {
    background: var(--gradient-primary);
  }
}

.section-home-hero {
  min-height: 720px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  position: relative;

  &__inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &::before {
      content: "";
      position: absolute;

      top: 230px;
      left: 50px;

      height: 193px;
      width: 1px;
      background: #000;

      pointer-events: none;
    }
  }

  .hero-title {
    margin-left: 30px;
    font-size: 70px;
    &__stroke {
      display: block;
    }
    &__fill {
      display: block;
    }
  }
}
.section-inner-hero {
  min-height: 420px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  position: relative;

  &__inner {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);

    text-align: center;
    width: 100%;
    left: 0;
  }
}

.section-advantage {
  margin-top: 40px;
  margin-bottom: 40px;

  min-height: 520px;
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;

  position: relative;

  &--right {
    background-position: right top;
  }
}

.hero-title {
  font-family: "Titillium Web", serif;
  font-size: 100px;
  font-weight: bold;
  line-height: 85%;

  &__stroke {
    // display: block;
    // color: transparent;
    // text-stroke: 2px #000;
    // -webkit-text-stroke: 2px #000;
  }
  &__fill {
    // display: block;
  }
}

.big-stacked-icon {
  text-align: center;
  margin-bottom: 30px;

  &__icon-wrap {
    line-height: 50%;
    margin-bottom: 0;
    i {
      display: block;
      font-size: 60px;

      background: var(--gradient-primary);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &::before {
        width: 100%;
      }
    }
  }
  &__value {
    font-family: "Titillium Web";
    font-weight: bold;
    font-size: 60px;
    background: var(--gradient-primary);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-bottom: 30px;
  }
  &__title {
    font-weight: 300;
    font-size: 22px;
    line-height: 100%;
    color: #000;
  }
}

.address-box {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  &__item {
    width: 33.3%;
    padding: 0 20px;
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid black;
    }

    &-title {
      font-family: "Titillium Web";
      font-weight: bold;
      font-size: 30px;
      color: #000;

      margin-bottom: 24px;
    }
    &-element {
      font-weight: 300;
      font-size: 20px;
      color: #000;
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &-title {
        font-weight: bold;
      }
    }
  }
}

.service-box {
  display: flex;
  margin-left: 150px;
  margin-bottom: 50px;
  &__icon-wrap {
    margin-right: 80px;
    width: 180px;
    flex: 0 0 auto;
    i {
      display: flex;
      font-size: 120px;
      background: var(--gradient-primary);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.history-year {
  font-family: "Titillium Web";
  font-weight: 300;
  font-size: 120px;
  font-style: italic;
  line-height: 30px;
  color: var(--color-primary);
}
.history-box {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .history-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    color: var(--color-primary);
    margin-bottom: 10px;
  }
  .history-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 120%;
    color: #000;
    p {
      margin-bottom: 30px;
    }
  }
}

.big-icon {
  &__icon-wrap {
    line-height: 50%;
    margin-bottom: 30px;
    i {
      display: block;
      font-size: 36px;

      background: var(--gradient-primary);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      &::before {
        width: 100%;
      }
    }
  }
  &__text {
    text-align: center;

    font-weight: 300;
    font-size: 22px;
    line-height: 150%;

    a {
      color: inherit;
    }
  }
}

.contact-form {
  .ty-button {
    width: 100%;
    max-width: 300px;
  }
}

.contact-map {
  max-width: 800px;
  width: 100%;
  margin: auto;
}

.mission-carousel-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  .mission-carousel {
    height: 100%;

    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.mission-text {
  max-width: 80%;
  width: 100%;
  margin: auto;
}
